<div class="alert alert-danger"
*ngIf="alumnos.length === 0">
<h4 class="mb-0">
Atención!!! No existe el alumno con el DNI ingresado. 
Por consultas escribir a certificadosescolares@educacionlarioja.com
</h4>
</div>

<div class="card-body" align="left"
*ngIf="alumnos.length >0"
>   
<button type="button" *ngIf="txtNombreTutor && txtDniTutor && txtLugarTrabajo" class="btn btn-danger" (click)='download() '>
  Descargar Certificado
</button>
  <div class="row" >
    
  
    <div class="actions" >
      <h3 style="color:#FF0000"> * Insertar datos complementarios del padre o tutor para completar el certificado</h3> 

          <input type="text" id="txt_1" [(ngModel)]="txtNombreTutor"  STYLE="border-color:blue"
         class="form-control"
          placeholder="NOMBRE COMPLETO" 
          #txtnombre>           
      <hr>
           <input type="number" id="txt_2" [(ngModel)]="txtDniTutor"  STYLE="border-color:blue"
          class="form-control"
           placeholder="DNI" 
           #txtdni>
  
           <hr>
           <input type="text" id="txt_3" [(ngModel)]="txtLugarTrabajo" STYLE="border-color:blue"
           class="form-control"
           placeholder="LUGAR DE PRESENTACIÓN" 
           #txtTermino>
        
           
           

    <br> <br>
     
 
    


</div>

 
    <div></div>


  <div class="col-12" id="block" *ngIf="txtNombreTutor && txtDniTutor && txtLugarTrabajo">
            <blockquote class="blockquote text-center">
                <h2>CONSTANCIA DE ALUMNO REGULAR</h2>
                <tr *ngFor="let alumno of alumnos"
                class= "animated fadeIn fast"> 
                <p class="mb-0"><strong>{{alumno.institucion}} C.U.E. N° {{alumno.cue}} </strong> </p>      
                <footer class="blockquote-footer"><cite title="Source Title"> <strong>PROVINCIA DE LA RIOJA</strong></cite> </footer>
                 <br>                
                 <p align="left"> <strong> Por la presente deja constancia que <strong>{{alumno.nombre}}</strong> {{alumno.tipo}} <strong>{{alumno.DNI}} </strong>
                 es alumno/a {{alumno.condicion}} de esta institución educativa, cursando {{alumno.anio}} {{alumno.carrera }} correspondiente al ciclo lectivo <strong>{{alumno.ciclo}}</strong> </strong> </p> 
                <p align="left"> <strong>A pedido del padre/tutor <strong>{{ txtNombreTutor  | uppercase  }}</strong>, DNI <strong>{{ txtDniTutor }}</strong> para ser presentado ante el/la {{ txtLugarTrabajo | uppercase  }}.
                  <br> Fecha de emisión del presente certificado {{ myDate | date: 'd / M / y - h:mm:ss' }}-</strong></p> 
                
                <br>      
                 <img src="../../../assets/images/logo_educacion.png" align="left">
                 <img src="../../../assets/images/FotoJet.png" align="right">
                 <ngx-qrcode 
                  [elementType]="elementType" 
                  [value]= "value" 
                  cssClass="aclass"
                  errorCorrectionLevel="correctionLevel">
                </ngx-qrcode>
                 
                          </tr> 
                          
                        
             <br>   
             
             <input type="hidden" id="txt_4" [(ngModel)]="value" STYLE="border-color:blue"
           class="form-control"
           #txtTermino>
           <div>
          
            <p  style= "font-size: 15px" align="left">*Validación del presente certificado mediante Código QR*</p> 
            
          </div>
            </blockquote> 
                        
          </div>   
        </div>
    </div>

 