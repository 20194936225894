import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/Router';
import { PagesRoutingModule } from './pages/pages.routing';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { AuthRoutingModule } from './auth/auth.routing';
import { CertDocenteComponent } from './pages/cert_docente/cert-docente.component';
import { ListCertDocenteComponent } from './pages/cert_docente/list-cert-docente/list-cert-docente.component';



const routes: Routes = [

  // path: '/dashboard' PagesRouting
  // path: '/auth' AuthRouting
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: 'cert_docente', component: CertDocenteComponent },
  {path: 'list_docente', component: ListCertDocenteComponent},
  {path: '**', component: NopagefoundComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    PagesRoutingModule,
    AuthRoutingModule

  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule { }
