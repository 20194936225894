import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginForm } from '../interfaces/login-form.interface';
import { Observable, of } from 'rxjs';
import { Usuario } from '../models/usuario.models';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public usuario: Usuario;

  constructor( private http: HttpClient,
               private router: Router ) { }

  // tslint:disable-next-line: typedef
  logout(){
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

 validarToken(): Observable<boolean> {
   const token = localStorage.getItem('token') || '';
   return this.http.get(`${base_url}/auth/renew`, {
     headers: {
       'x-token': token
     }
   }).pipe(
      tap( (resp: any) => {       
           localStorage.setItem('token', resp.token);
     }),
     map( resp => true),
     catchError( error => of(false) )
   );
 }

  // tslint:disable-next-line: typedef
  login( formData: LoginForm ){

    return this.http.post(`${base_url}/auth`, formData)
                .pipe(
                  map( (resp: any) => {
                    const { email,nombre,uid } = resp.usuario;
                    this.usuario = new Usuario( nombre, email, '', uid );
                    localStorage.setItem('token', resp.token );
                    return true;
                  }),
                  catchError( error => of(false) )
                );




}

}

