<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr >
        <th scope="col">Nombre</th>
        <th scope="col">Dni</th>
        <th scope="col">Rol</th>
        <th scope="col">Domicilio</th>
        <th scope="col">Lugar Presentación</th>
        <th scope="col">Inicio Aisl.</th>
        <th scope="col">Fin Aisl.</th>
        <th scope="col">Fecha Presentación</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let doc of listDocentes">
        <td>{{doc.nombre_del_docente}}</td>
        <td>{{doc.dni}}</td>
        <td>{{doc.rol}}</td>
        <td>{{doc.domicilio}}</td>
        <td>{{doc.escuela}}</td> 
        <td>{{doc.fecha_ini_ais | date: 'dd/MM/y' }}</td>
        <td>{{doc.fecha_fin_ais | date: 'dd/MM/y'  }}</td>
        <td>{{doc.emision | date: 'dd/MM/y' }}</td>
      </tr>
    </tbody>
    
  </table>
  
  </div>