import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Docente } from 'src/app/models/docente.model';
import { DocenteService } from '../../services/docente.service';
import { ToastrService } from 'ngx-toastr';
import * as html2pdf from 'html2pdf.js';



@Component({
  selector: 'app-cert-docente',
  templateUrl: './cert-docente.component.html',
  styleUrls: ['./cert-docente.component.css']
})
export class CertDocenteComponent implements OnInit {

  docentesForm: FormGroup;


  myDate = Date.now();
  txtNombreDocente: string;
  txtDniDocente: string;
  txtRol: string;
  txtDomicilio: string;
  txtEscuela: string;
  txtfecha_ini_ais: string;
  txtfecha_fin_ais: string;
  txtEmision: string;

  constructor(private fb: FormBuilder,
    private router: Router,
    private _docenteService: DocenteService,
    private toastr: ToastrService
     ) {
    
    this.docentesForm = this.fb.group({
      nombre: ["", Validators.required],
      dniDocente: ["", Validators.required],
      Rol: ["", Validators.required],
      Domicilio: ["", Validators.required],
      Escuela:["", Validators.required],
      fecha_ini_ais:["", Validators.required],
      fecha_fin_ais:["", Validators.required],
      Emision:["", Validators.required]
    }

    )
               }

  ngOnInit(): void {}
 
  download() {

      const options = {
      margin:       0.1,
      filename: 'Certificado_docente.pdf',
      image: {type: 'png', quality: 9},
      html2canvas: {},
      jsPDF: {format: 'a4', orientation: 'portrait'}
    }

    const element: Element = document.getElementById('block')
    html2pdf()
      .from(element)
      .set(options)
      .save()
  }

  agregarDocente() {
    const DOCENTE: Docente = {
      //_id: this.docentesForm.get('_id')?.value,
      nombre_del_docente: this.docentesForm.get('nombre')?.value,
      dni: this.docentesForm.get('dniDocente')?.value,
      rol: this.docentesForm.get('Rol')?.value,
      domicilio: this.docentesForm.get('Domicilio')?.value,
      escuela: this.docentesForm.get('Escuela')?.value,
      fecha_ini_ais: this.docentesForm.get('fecha_ini_ais')?.value,
      fecha_fin_ais: this.docentesForm.get('fecha_fin_ais')?.value,
      emision: this.docentesForm.get('Emision')?.value,
    }
    // console.log('el array es:', DOCENTE);
    this._docenteService.guardarDocente(DOCENTE).subscribe(data => {
      this.toastr.success('El certificado fue generado con exito!', 'Certificado Registrado!');
    }, error => {
      console.log(error);
      this.docentesForm.reset();
    } )
    

          
  }

}
