import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/Router';
import { AuthGuard } from '../guards/auth.guard';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { AlumnoComponent } from './certificados/alumno.component';
import { CertificadosComponent } from './certificados/certificados.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { CertDocenteComponent } from './cert_docente/cert-docente.component';
import { ListCertDocenteComponent } from './cert_docente/list-cert-docente/list-cert-docente.component';
import { MenuDocenteComponent } from './cert_docente/menu-docente/menu-docente.component';
import { AltaDocenteComponent } from './cert_docente/alta-docente/alta-docente.component';
import { VerificarqrComponent } from './busqueda/verificarqr/verificarqr.component';



const routes: Routes = [

    {
        path: 'dashboard',
        component: PagesComponent,
        canActivate: [AuthGuard],
        children: [
        { path: '', component: DashboardComponent, data: { titulo: 'Principal' } },
        { path: 'certificados', component: CertificadosComponent, data: { titulo: 'Emisión de Certificados' } },
        { path: 'alumno', component: AlumnoComponent, data: { titulo: 'Alumno Certificado' } },
        { path: 'cert_docente', component: CertDocenteComponent, data: { titulo: 'Certificado docente' } },
        { path: 'alta_docente', component: AltaDocenteComponent, data: { titulo: 'Certificado de ALTA docente' } },
        { path: 'list_docente', component: ListCertDocenteComponent, data: { titulo: 'Certificados docentes generados' } },
        { path: 'menu_docente', component: MenuDocenteComponent, data: { titulo: 'Menu de opciones certificados Docentes' } },
        { path: 'buscar/:termino', component: BusquedaComponent, data: { titulo: 'Emisión de Certificado del Alumno' } },
        { path: 'verificar___________/:termino', component: VerificarqrComponent, data: { titulo: 'Verificar QR' } },
    ]
      },
];

@NgModule({
    imports: [ RouterModule.forRoot(routes)],
    exports: [ RouterModule ]
})

export class PagesRoutingModule {}

