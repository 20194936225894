<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class=""  aria-expanded="false">
                        <img src="./assets/images/users/profile.png" alt="user" />
                        <span class="hide-menu">invitado</span></a>
                   
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">BIENVENIDOS!</li>
                <li> <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><i class="mdi mdi-gauge"></i><span class="hide-menu">MENU<span class="label label-rouded label-themecolor pull-right">1</span></span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li routerLink="/login"><a>Salir</a></li>
   
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
