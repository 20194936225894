import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-docente',
  templateUrl: './menu-docente.component.html',
  styleUrls: ['./menu-docente.component.css']
})
export class MenuDocenteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
