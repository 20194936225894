import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Docente } from '../models/docente.model';
import { Observable } from 'rxjs';



const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class DocenteService {

  constructor(private http: HttpClient) { }
  
  getDocentes(): Observable<any>{

    const url = `${base_url}/docentes`;
    return this.http.get(url)
    .pipe(
      map(( resp: {ok: boolean, docentes:Docente[]}) => resp.docentes)
    );
  }

  crearDocente(docente:Docente): Observable<any> {
    return this.http.post(base_url, docente);
  }

   actualizarDocente( _id: string, nombre: string){
    const url = `${base_url}/docentes/${_id}`;
    return this.http.put (url, { nombre });
   }
  
  guardarDocente(docente: Docente): Observable<any>{
    const url = `${base_url}/docentes`;
    return this.http.post(url, docente);
}


}







