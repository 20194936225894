<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<br>
    <h3 align=center>SISTEMA DE EMISIÓN DE CERTIFICADOS DE ESCOLARIDAD</h3> 
<hr>
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(../assets/images/background/login-register.jpg);">
 
    <div class="login-box card">
    <div class="card-body">
        <form class="form-horizontal form-material"
        autocomplete="off"
        [formGroup]="loginForm"
        (ngSubmit)="login()"
        id="loginform">

            <a href="javascript:void(0)" class="text-center db"><img src="" alt="" /><br/><img src="../assets/images/logo_educacion.png" alt="Home" /></a>
            <div class="form-group m-t-40">
            
            </div>
            
            
            <div class="form-group row">
                <div class="col-md-12">
                    
                    <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class=""></i></a> </div>
            </div>
            <div class="form-group text-center m-t-20">
                <div class="col-xs-12">
                    <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Ingresar</button>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                    <br>
                    <a href="javascript:void(0)" class="text-center db"><img src="" alt="" /><br/><img src="../assets/images/gob.jpg" alt="Home" /></a>
                </div>
            </div>
            <div class="form-group m-b-0">
                <div class="col-sm-12 text-center">
                     <a href="" class="text-primary m-l-5"><b></b></a>
                </div>
            </div>
        </form>
        <form class="form-horizontal" id="recoverform" action="index.html">
            <div class="form-group ">
                <div class="col-xs-12">
                    <h3>Recover Password</h3>
                    <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
                </div>
            </div>
            <div class="form-group ">
                <div class="col-xs-12">
                    <input class="form-control" type="text" required="" placeholder="Email">
                </div>
            </div>
            <div class="form-group text-center m-t-20">
                <div class="col-xs-12">
                    <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                </div>
            </div>
        </form>
    </div>
</div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->