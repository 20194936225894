import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario.models';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent {

  public usuario: Usuario;

  constructor( private usuarioService: UsuarioService,
              private router: Router ) { 

                
              }

  logout(){
    this.usuarioService.logout();
  }

  buscar( termino: string ) {
   
    if ( termino.length < 8 ){
      
      Swal.fire(
        'Atención!!',
        'DNI: Verifique la cantidad de números ingresados',
        'warning'
      )
      return;
    }

    this.router.navigateByUrl(`dashboard/buscar/${ termino }`);
  }

}
