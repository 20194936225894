
<div id="main-wrapper">


    <app-header></app-header>
    
    <app-sidebar></app-sidebar>
    
    <div class="page-wrapper">
    
    
    <div class="container-fluid">
        <app-breadcrumbs></app-breadcrumbs>
    
    <!-- ============================================================== -->
        <!-- Start Page Content -->
        <!-- ============================================================== -->
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End PAge Content -->
        <!-- ============================================================== -->
        </div>
    
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">
        © 2020 Ministerio de Educación - Secretaría de Planeamiento Educativo - Unidad de Estadística
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
    </div>
    
    
    
    </div>
