import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/Router';
import { LoginComponent } from './login/login.component';



const routes: Routes = [

{path: 'login', component: LoginComponent}

];

@NgModule({
    imports: [ RouterModule.forChild(routes)],
    exports: [ RouterModule ]
})

export class AuthRoutingModule {}

