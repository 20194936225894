


  <form class="mt-3" [formGroup]="docentesForm" (ngSubmit)="agregarDocente()">
    <button type="button" type="submit" [disabled]="docentesForm.invalid" *ngIf="txtNombreDocente && txtDniDocente && txtRol"
    class="btn btn-danger" (click)='download()'>
    Descargar Certificado Docente
    </button>
    <div class="actions" >
      <h3 style="color:#FF0000"> * Insertar datos del docente para completar el certificado</h3> 
          <input type="text" formControlName="nombre" id="txt_1" [(ngModel)]="txtNombreDocente"  STYLE="border-color:blue"
         class="form-control"
          placeholder="Nombre completo del docente" 
          #txtnombre> 
          <div class="text-danger"
          *ngIf="docentesForm.get('nombre').hasError('required') && docentesForm.get('nombre').touched">
            <span>
              El nombre del Docente es Obligatorio!
            </span>
          </div>         
      <hr>
      <input type="number" formControlName= "dniDocente" id="txt_2" [(ngModel)]="txtDniDocente"  STYLE="border-color:blue"
      class="form-control"
       placeholder="DNI" 
       #txtDni>
       <div class="text-danger"
       *ngIf="docentesForm.get('dniDocente').hasError('required') && docentesForm.get('dniDocente').touched">
         <span>
           El DNI del Docente es Obligatorio!
         </span>
       </div>           
      <hr>
      <input type="text" formControlName= "Rol" id="txt_3" [(ngModel)]="txtRol"  STYLE="border-color:blue"
      class="form-control"
      placeholder="Rol del docente (MAESTRO - PROFESOR)">
      <div class="text-danger"
          *ngIf="docentesForm.get('Rol').hasError('required') && docentesForm.get('Rol').touched">
            <span>
              El Rol del Docente es Obligatorio!
            </span>
          </div> 
      <hr>
      <input type="text"  formControlName= "Domicilio" id="txt_4" [(ngModel)]="txtDomicilio"  STYLE="border-color:blue"
      class="form-control"
       placeholder="Domicilio (Calle - número y barrio)" 
       #txtDom>  
       <div class="text-danger"
       *ngIf="docentesForm.get('Domicilio').hasError('required') && docentesForm.get('Domicilio').touched">
         <span>
           El Domicilio es Obligatorio!
         </span>
       </div>          
      <hr>
      <input type="text" formControlName= "Escuela" id="txt_5" [(ngModel)]="txtEscuela"  STYLE="border-color:blue"
      class="form-control"
       placeholder="Escuela - lugar de presentación " 
       #txtEsc>  
       <div class="text-danger"
       *ngIf="docentesForm.get('Escuela').hasError('required') && docentesForm.get('Escuela').touched">
         <span>
           El Lugar de presentación es Obligatorio!
         </span>
       </div>           
      <hr>
      Fecha de inicio del aislamiento
      <input type="date" formControlName= "fecha_ini_ais" id="txt_6" [(ngModel)]="txtfecha_ini_ais"  STYLE="border-color:blue"
      class="form-control"
       placeholder="Fecha de inicio del aislamiento" 
       #txtfecha_ini>   
       <div class="text-danger"
       *ngIf="docentesForm.get('fecha_ini_ais').hasError('required') && docentesForm.get('fecha_ini_ais').touched">
         <span>
           La fecha de inicio del Aisalmiento es obligatoria!
         </span>
       </div>         
      <hr>
      Fecha de finalización del aislamiento
      <input type="date" formControlName= "fecha_fin_ais" id="txt_7" [(ngModel)]="txtfecha_fin_ais"  STYLE="border-color:blue"
      class="form-control"
       placeholder="Fecha de finalización del aislamiento" 
       #txtfecha_fin> 
       <div class="text-danger"
       *ngIf="docentesForm.get('fecha_fin_ais').hasError('required') && docentesForm.get('fecha_fin_ais').touched">
         <span>
           La fecha de finalización del Aisalmiento es obligatoria!
         </span>
       </div>            
      <hr>
      Fecha de emisión del certificado
      <input type="date" formControlName= "Emision" id="txt_5" [(ngModel)]="txtEmision"  STYLE="border-color:blue"
      class="form-control"
       placeholder="Fecha de emisión" 
       #txtEsc>  
       <div class="text-danger"
       *ngIf="docentesForm.get('Emision').hasError('required') && docentesForm.get('Emision').touched">
         <span>
           La Fecha de emisión del certificado es obligatoria!
         </span>
       </div>              
      <hr>
    </div>
  </form>
     <div>    
      <div class="col-12" id="block" *ngIf="txtNombreDocente && txtDniDocente && txtRol" >
        <blockquote class="blockquote text-center">
            <img src="../../../assets/images/cert_doc_encabezado.JPG" align="center">
            <p class="mb-1" align="justify" style="font-family: 'PT Sans'; font-size:x-large;; color: black">---Que de conformidad con las recomendaciones y medidas de prevención establecidas
            en el Protocolo del Ministerio de Salud de la Nación sobre Coronavirus COVID-19 y según lo informado por el
            Ministerio de Salud de la Provincia, el/la <strong style="font-weight: bold"> {{ txtRol   | uppercase  }} </strong> Sr/a  <strong style="font-weight: bold"> {{ txtNombreDocente   | uppercase  }} </strong>
            DNI<strong style="font-weight: bold"> {{txtDniDocente}}</strong>, empleado de Ministerio de Educación, deberá permanecer en AISLAMIENTO PREVENTIVO DOMICILIARIO 
            - Sin estar facultado de salir del mismo ante razón alguna en <strong style="font-weight: bold" > {{ txtDomicilio  | uppercase  }} </strong>, desde el día
            <strong style="font-weight: bold" >  {{ txtfecha_ini_ais | date: 'dd/MM/y '}} </strong> hasta el día <strong style="font-weight: bold" > {{ txtfecha_fin_ais | date: 'dd/MM/y ' }} </strong> 
            del cte. año, en tutela de la Salud Pública General.---
              <br>
              Que a los fines de ser presentada ante las autoridades de/del {{txtEscuela}},
              se expide el presente con fecha {{txtEmision | date: 'dd/MM/y'}}.--- 
            </p>
            <br>
            <br>
            <img src="../../../assets/images/KN_FIRMA_Nueva.jpg" align="left">    
            <img src="../../../assets/images/sello_coe.jpg" align="center">
            <img src="../../../assets/images/firma_coe.jpg" align="right">
        </blockquote>    
      </div> 


      