<div class="alert alert-danger"
*ngIf="alumnos.length === 0">
<h4 class="mb-0">
Atención!!! No existe el alumno con el DNI ingresado. 
Por consultas escribir a certificadosescolares@educacionlarioja.com
</h4>
</div>

<div class="card-body" align="left"
*ngIf="alumnos.length >0"
>   

  <div class="row" >
    <button type="button" *ngIf="txtNombreTutor && txtDniTutor && txtLugarTrabajo" class="btn btn-danger" (click)='download() '>
      Descargar Certificado
    </button>
  
  

 
    <div></div>


  <div class="col-12" id="block" >
            <blockquote class="blockquote text-left">
                <h2>QR INFO</h2>
                <tr *ngFor="let alumno of alumnos"
                class= "animated fadeIn fast"> 
                 Institución: {{alumno.institucion}} <br>
                 C.U.E. N°: {{alumno.cue}}   <br>   
                Alumno:{{alumno.nombre}}<br>
                D.N.I:{{alumno.DNI}} <br>
                Curso: {{alumno.anio}} <br>
                <ngx-qrcode 
                [elementType]="elementType" 
                [value]= "value" 
                cssClass="aclass"
                errorCorrectionLevel="correctionLevel">
              </ngx-qrcode>
                <br>      
                
                
                          </tr> 
                          
                        
             <br>   
             
           
            
            </blockquote>                   
          </div>   
        </div>
    </div>

 