import { Component, OnInit } from '@angular/core';
import { Docente } from 'src/app/models/docente.model';
import { DocenteService } from '../../../services/docente.service';

@Component({
  selector: 'app-list-cert-docente',
  templateUrl: './list-cert-docente.component.html',
  styleUrls: ['./list-cert-docente.component.css']
})
export class ListCertDocenteComponent implements OnInit {

  listDocentes: Docente[] = [];

  

  constructor(private _docenteService: DocenteService,
  ) { }

  ngOnInit(): void {
    this.obtenerDocentes();
  }

  obtenerDocentes() {
    this._docenteService.getDocentes().subscribe(data => {
      console.log('data desde obtener docentes', data);
      this.listDocentes = data;
    }, error => {
      console.log('error desde obtener docentes',error);
    })
  }



  
}