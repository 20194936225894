import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Alumno } from 'src/app/models/alumno.model';
import { BusquedasService } from 'src/app/services/busquedas.service';
import * as html2pdf from 'html2pdf.js';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';



@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css']
})
export class BusquedaComponent implements OnInit {

  myDate = Date.now();
  txtNombreTutor: string;
  txtDniTutor: string;
  txtLugarTrabajo: string;
  txtvalue = "https://certificadosapp.herokuapp.com/dashboard/verificar___________/" ;
  valor = "";
  title = 'Validación QR certificado de escolaridad';
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value="";
 
  public alumnos: Alumno[][];
  constructor( private activatedRoute: ActivatedRoute,
              private busquedaService: BusquedasService  ) { }

  ngOnInit(): void {
    this.activatedRoute.params
    .subscribe( ( { termino }) => this.busquedaGlobal (termino ));
    
    
    
  }
  
 
  busquedaGlobal( termino: string ){
    
    this.busquedaService.busquedaGlobal(termino)
    .subscribe( (resp: any ) => {
      this.valor = termino;
      console.log(this.valor);
      this.value = this.txtvalue + this.valor;
      this.alumnos = resp.alumnos;
    })
  }
  
 
 
  download() {
    const options = {
      margin:       0.1,
      filename: 'Certificado_del_alumno.pdf',
      image: {type: 'png', quality: 9},
      html2canvas: {},
      jsPDF: {format: 'a4', orientation: 'portrait'}
    }

    const element: Element = document.getElementById('block')
    html2pdf()
      .from(element)
      .set(options)
      .save()
  }

 
    

 
}
