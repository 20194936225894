import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

//componentes
import { DashboardComponent } from './dashboard/dashboard.component';
import { PagesComponent } from './pages.component';
import { CertificadosComponent } from './certificados/certificados.component';
import { AlumnoComponent } from './certificados/alumno.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { Pages1Component } from './pages1.component';
import { CertDocenteComponent } from './cert_docente/cert-docente.component';
import { ListCertDocenteComponent } from './cert_docente/list-cert-docente/list-cert-docente.component';
import { MenuDocenteComponent } from './cert_docente/menu-docente/menu-docente.component';
import { AltaDocenteComponent } from './cert_docente/alta-docente/alta-docente.component';
import { VerificarqrComponent } from './busqueda/verificarqr/verificarqr.component';





@NgModule({
  declarations: [
    DashboardComponent,
    PagesComponent,
    CertificadosComponent,
    AlumnoComponent,
    BusquedaComponent,
    Pages1Component,
    CertDocenteComponent,
    ListCertDocenteComponent,
    MenuDocenteComponent,
    AltaDocenteComponent,
    VerificarqrComponent,
  ],
  exports: [
    DashboardComponent,
    PagesComponent,
    CertificadosComponent,
    
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxQRCodeModule,
    ToastrModule.forRoot()
  ]
})
export class PagesModule { }
