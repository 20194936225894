
<h2>Bienvenido al sistema de impresión de certificados de escolaridad</h2>
<br>
<hr>
 <h4>Como realizar la emisión del certificado escolar?<h4>
     <br>
<h5>Desde el buscador (parte superior de la página) coloque el DNI del alumno, para iniciar
     la busqueda desde un celular presionar la tecla "Ir", desde una PC mediante
     la teclar "Enter". <br>
     Recuerde que el ingreso del DNI deberá ser sin puntos ni espacios en blanco.</h5>
<br>

<img src="../assets/images/indica.jpg" alt="homepage" class="dark-logo" align="center"/>

<br>
<h5>Surgirá a continuación los campos complementarios que deberá completar con los datos correspondientes al padre
     o tutor como se muestra a continuación:  
</h5>
<img src="../assets/images/complementarios.jpg" alt="homepage" class="dark-logo" align="center"/>

<h5>Surgirá a continuación el botón de descarga del certificado en formato PDF
     denominado "Descargar certificado"</h5>

<img src="../assets/images/descargar.jpg" alt="homepage" class="dark-logo" align="center"/>
<br>
<br>
<h5>Una vez comenzado el proceso de descarga, deberá esperar unos segundos para obtener el mismo.</h5>