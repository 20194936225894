import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './nopagefound.component.html',
  styleUrls: ['./nopagefound.component.css']
})
export class NopagefoundComponent  {

  year = new Date().getFullYear();

}




