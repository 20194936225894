import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DocenteService } from '../../../services/docente.service';
import { Docente } from '../../../models/docente.model';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-alta-docente',
  templateUrl: './alta-docente.component.html',
  styles: [
  ]
})
export class AltaDocenteComponent implements OnInit {


  docentesForm: FormGroup;
  listDocentes: Docente[] = [];

  myDate = Date.now();
  txtNombreDocente: string;
  txtDniDocente: string;
  txtRol: string;
  txtfecha_ini_ais: string;
  txtfecha_fin_ais: string;
  txtEmision: string;
  
  constructor(
    private _docenteService: DocenteService,
    private fb: FormBuilder,
  ) {

    this.docentesForm = this.fb.group({
      nombre: ["", Validators.required],
      dniDocente: ["", Validators.required],
      Rol: ["", Validators.required],
      fecha_ini_ais: ["", Validators.required],
      fecha_fin_ais: ["", Validators.required],
      Emision: ["", Validators.required]
    })
  }

  ngOnInit(): void {
  }

  downloadAlta() {

    const options = {
    margin:       0.1,
    filename: 'Certificado_Alta_docente.pdf',
    image: {type: 'png', quality: 9},
    html2canvas: {},
    jsPDF: {format: 'a4', orientation: 'portrait'}
  }

  const element: Element = document.getElementById('block')
  html2pdf()
    .from(element)
    .set(options)
    .save()
}

}
